import React from "react";
import { observer } from "mobx-react";

import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";
import Tooltip from "../tooltip/Tooltip";

const WaterDropIcon = ({ fill = "#c3c3c3" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
    >
      <path
        d="M8.008 0S.076 11.329.013 15.838A.558.558 0 0 1 0 15.9 8.057 8.057 0 0 0 8.008 24a8.047 8.047 0 0 0 8-8.1v-.064c-.08-4.507-8-15.836-8-15.836m5.608 15.863a2.05 2.05 0 1 1-4.1 0v-.026a2.05 2.05 0 1 1 4.1 0z"
        fill={fill}
      />
    </svg>
  );
};

const getDisributionQuality = (uniformity) => {
  if (uniformity < 70) return "bad";
  if (uniformity >= 70 && uniformity <= 77) return "good";
  if (uniformity >= 78 && uniformity <= 85) return "excellent";
  if (uniformity >= 86) return "exceptional";
};

const colorMapping = {
  default: "#c3c3c3",
  bad: "#ff7600",
  good: "#ffe200",
  excellent: "#c5d75d",
  exceptional: "#33bc12",
};
const coloredDropsCountMapping = {
  bad: 1,
  good: 2,
  excellent: 3,
  exceptional: 4,
};
const getDisributionColoring = (uniformity) => {
  const quality = getDisributionQuality(uniformity);

  let result = [];
  for (let i = 0; i < 4; i++) {
    if (i >= coloredDropsCountMapping[quality]) {
      result.push(colorMapping["default"]);
      continue;
    }

    result.push(colorMapping[quality]);
  }
  return result;
};

const descriptionMapping = {
  exceptional:
    "texts.steps.sprinklers.coverage.distribution.exceptional.tooltip.text",
  excellent:
    "texts.steps.sprinklers.coverage.distribution.excellent.tooltip.text",
  good: "texts.steps.sprinklers.coverage.distribution.good.tooltip.text",
  bad: "texts.steps.sprinklers.coverage.distribution.bad.tooltip.text",
};

const getDisributionTooltip = (uniformity) => {
  const quality = getDisributionQuality(uniformity);
  return descriptionMapping[quality];
};

const titleMapping = {
  exceptional:
    "texts.steps.sprinklers.coverage.distribution.exceptional.title.text",
  excellent:
    "texts.steps.sprinklers.coverage.distribution.excellent.title.text",
  good: "texts.steps.sprinklers.coverage.distribution.good.title.text",
  bad: "texts.steps.sprinklers.coverage.distribution.bad.title.text",
};
const getDisributionTitleId = (uniformity) => {
  const quality = getDisributionQuality(uniformity);
  return titleMapping[quality];
};

const SprinklersCoverageLegend = ({ legend, uniformity }) => {
  const { formatMessage } = useIntl();

  const colorDistribution = getDisributionColoring(uniformity);
  const distributionTitleId = getDisributionTitleId(uniformity);
  const distributionTooltipId = getDisributionTooltip(uniformity);
  return (
    <div
      className={`coverage-legend-wrapper ${
        uniformity ? "with-uniformity" : ""
      }`}
    >
      <div className="coverage-legend">
        {legend.map(({ color, label }, key) => {
          return (
            <div key={label} className="legend-item">
              <i style={{ background: color }}></i>
              {formatMessage({ id: label })}
            </div>
          );
        })}
      </div>
      {uniformity != null && (
        <div className="distribution-uniformity-legend">
          <div className="distribution-uniformity-title">
            {formatMessage({
              id: "texts.steps.sprinklers.coverage.distribution.title",
            })}
          </div>
          <Tooltip
            position="top"
            tooltip={formatMessage({ id: distributionTooltipId })}
          >
            <div className="water-drops">
              {colorDistribution.map((fillColor, index) => {
                return (
                  <i key={index + fillColor}>
                    {<WaterDropIcon fill={fillColor} />}
                  </i>
                );
              })}
            </div>
          </Tooltip>
          <div>{formatMessage({ id: distributionTitleId })}</div>
          <div style={{ color: "rgb(5, 35, 52)" }}>
            ({Math.min(uniformity, 100)}%)
          </div>
        </div>
      )}
    </div>
  );
};

let SprinklersCoverageLegendWithState = () => {
  const { uiState } = useStores();

  return (
    <>
      {uiState.plan?.precipitationCoverage &&
        uiState.calculatingTitle == null &&
        uiState.stepIdx === uiState.stepIdxByName("sprinklers") && (
          <SprinklersCoverageLegend
            legend={uiState.settingsState.precipitationBins}
            uniformity={uiState.plan?.uniformity}
          />
        )}
    </>
  );
};

SprinklersCoverageLegendWithState = observer(SprinklersCoverageLegendWithState);
export default SprinklersCoverageLegendWithState;
